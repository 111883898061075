/**
 * @overview This is the main react flowroot application starting point. React initial JSX components are presented here wrapped in an error component.
 * @Author Andy O'Connor
 */

import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { UserProvider } from './contexts/userContext'
import ProtectedRoute from './components/ProtectedRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/Login'))
//const Register = React.lazy(() => import('./views/pages/Register'))
//<Route exact path="/register" name="Register Page" element={<Register />} />
const Page403 = React.lazy(() => import('./views/pages/Page403'))
const Page404 = React.lazy(() => import('./views/pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/Page500'))
const ConfirmEmail = React.lazy(() => import('./views/pages/ConfirmEmail'))
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword'))

const App = () => {
    return (
      <BrowserRouter>
        <UserProvider>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/403" name="Page 403" element={<Page403 />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route exact path="/confirmEmail" name="confirmEmail" element={<ConfirmEmail />} />
              <Route exact path="/forgotPassword" name="forgotPassword" element={<ForgotPassword />} />
              <Route exact path="/resetPassword" name="resetPassword" element={<ResetPassword />} />
              <Route element={<ProtectedRoute />}>
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Route>
            </Routes>
          </Suspense>
        </UserProvider>
      </BrowserRouter>
    )
}

export default App
