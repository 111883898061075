/**
 * @overview This is a basic login redirect for protected routes.
 * @Todo Needs to be refactored to use a hook: https://usehooks.com/useAuth/
 * @Author Andy O'Connor
 */

import { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { UserContext } from '../contexts/userContext'

const ProtectedRoute = (props) => {
  // Fetching the user from the user context
  const { user } = useContext(UserContext)
  const location = useLocation()

  // If the user is not logged in we are redirecting them to the login page. Otherwise we are letting them to
  // continue to the page as per the URL using <Outlet />
  return user ? <Outlet /> : <Navigate to={`/login?redirectTo=${encodeURI(location.pathname)}`} />
}

export default ProtectedRoute
